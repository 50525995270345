import KesztetoBanner from "assets/images/keszteto-banner.jpg";
import TetotepitekBanner from "assets/images/tetotepitek-banner.jpg";
import DanubiaSaleBanner from "assets/images/danubia-sale-banner.jpg";
// import MagyarANyeroBanner from "assets/images/magyaranyero.jpg";
// import SynusSaleBanner from "assets/images/synus-sale-banner-2024-11.jpg";
// import Construma2024BannerLive from "assets/images/construma2024-banner-live.jpg";
// import AcskeresoBanner from "assets/images/acskereso-banner.jpg";
// import AjanlatunkBanner from "assets/images/ajanlatunk-banner.jpg";
// import Construma2024Banner from "assets/images/construma2024-banner.jpg";
// import MarkakereskedokBanner from "assets/images/markakereskedok-banner.jpg";
// import SynusSaleBanner from "assets/images/synus-sale-banner.jpg";
// import SynusSaleBanner from "assets/images/synus-sale-banner-2024-10.jpg";
// import SynusSaleBanner from "assets/images/synus-sale-banner-extended.jpg";
// import TetortenetedBanner from "assets/images/tetorteneted-banner.jpg";

export const banners = {
  hu: [
    {
      link: "https://terranteto.hu/keszteto",
      image: KesztetoBanner,
      alt: "KészTető banner"
    },
    {
      link: "https://tetotepitek.hu/",
      image: TetotepitekBanner,
      alt: "Tetőtépítek banner"
    },
    {
      link: "https://terranteto.hu/danubia/",
      image: DanubiaSaleBanner,
      alt: "Danubia akció"
    },
    // {
    //   link: "https://amagyartermek.hu/",
    //   image: MagyarANyeroBanner,
    //   alt: "Magyar a nyerő banner"
    // },
    // {
    //   link: "https://terranteto.hu/synus",
    //   image: SynusSaleBanner,
    //   alt: "Synus Akció"
    // },
    // {
    //   link: "https://otthonhorizontja.hu/",
    //   image: Construma2024BannerLive,
    //   alt: "Az otthon horizontja banner"
    // }
    // {
    //   link: "https://acskereso.hu/",
    //   image: AcskeresoBanner,
    //   alt: "Ácskereső banner"
    // },

    // {
    //   link: "https://terranteto.hu/ajanlataink/",
    //   image: AjanlatunkBanner,
    //   alt: "Ajánlataink"
    // },
    // {
    //   link: "https://tetorteneted.terranteto.hu/",
    //   image: TetortenetedBanner,
    //   alt: "Tetorteneted banner"
    // },
    // {
    //   link: "https://terranteto.hu/synus",
    //   image: SynusSaleBanner,
    //   alt: "Synus Akció"
    // },
    // {
    //   link: "https://terranteto.hu/markakereskedok/",
    //   image: MarkakereskedokBanner,
    //   alt: "Márkakereskedők banner"
    // }
  ]
};
