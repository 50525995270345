// Matrix with 25 elements based on items count
const stand1 = {
  stands: 3,
  28: [
    [0, 1, 1, 2, 3, 0, 1, 1, 2, 3, 4, 5, 5, 6, 7, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13],
    [14, 14, 14, 15, 15, 16, 16, 16, 17, 17, 18, 18, 18, 19, 19, 20, 20, 20, 19, 19, 21, 21, 21, 22, 22],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 26, 26, 27, 27, 27, 28, 28]
  ],
  29: [
    [0, 1, 1, 2, 3, 0, 1, 1, 2, 3, 4, 5, 5, 6, 7, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13],
    [14, 29, 29, 15, 15, 16, 16, 16, 17, 17, 18, 18, 18, 19, 19, 20, 20, 20, 19, 19, 21, 21, 21, 22, 22],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 26, 26, 27, 27, 27, 28, 28]
  ],
  30: [
    [0, 1, 1, 2, 3, 0, 1, 1, 2, 3, 4, 5, 5, 6, 7, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13],
    [14, 29, 29, 15, 15, 16, 16, 16, 17, 17, 18, 18, 18, 19, 19, 20, 20, 20, 19, 19, 21, 21, 21, 22, 22],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 26, 26, 27, 27, 27, 28, 28]
  ],
  31: [
    [0, 1, 1, 2, 3, 0, 1, 1, 2, 3, 4, 5, 5, 6, 7, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13],
    [14, 29, 29, 15, 15, 16, 16, 16, 17, 17, 18, 18, 18, 19, 19, 20, 20, 20, 19, 19, 21, 21, 21, 22, 22],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 30, 30, 27, 27, 27, 28, 28]
  ],
  32: [
    [0, 1, 1, 2, 3, 0, 1, 1, 2, 3, 4, 5, 5, 6, 7, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13],
    [14, 29, 29, 15, 15, 16, 16, 16, 17, 17, 18, 18, 18, 19, 19, 20, 20, 20, 19, 19, 21, 21, 21, 22, 22],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 30, 30, 27, 27, 27, 28, 28]
  ],
  40: [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
    [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  ]
};

const stand2 = {
  stands: 4,
  28: [
    [0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 2, 2, 3, 3, 3, 6, 6, 5, 5, 5, 7, 7, 4, 4, 4],
    [8, 8, 9, 10, 10, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13],
    [14, 14, 14, 15, 15, 16, 16, 16, 17, 17, 18, 18, 18, 19, 19, 20, 20, 20, 19, 19, 21, 21, 21, 22, 22],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 26, 26, 27, 27, 27, 27, 27]
  ],
  29: [
    [0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 2, 2, 3, 3, 3, 6, 6, 5, 5, 5, 7, 7, 4, 4, 4],
    [8, 8, 9, 10, 10, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13],
    [14, 14, 14, 15, 15, 16, 16, 16, 17, 17, 18, 18, 18, 19, 19, 20, 20, 20, 19, 19, 21, 21, 21, 22, 22],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 26, 26, 27, 27, 27, 28, 28]
  ],
  30: [
    [0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 2, 2, 3, 3, 3, 6, 6, 5, 5, 5, 7, 7, 4, 4, 4],
    [8, 8, 9, 10, 10, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13],
    [14, 29, 29, 15, 15, 16, 16, 16, 17, 17, 18, 18, 18, 19, 19, 20, 20, 20, 19, 19, 21, 21, 21, 22, 22],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 26, 26, 27, 27, 27, 28, 28]
  ],
  31: [
    [0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 2, 2, 3, 3, 3, 6, 6, 5, 5, 5, 7, 7, 4, 4, 4],
    [8, 8, 9, 10, 10, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13],
    [14, 29, 29, 15, 15, 16, 16, 16, 17, 17, 18, 18, 18, 19, 19, 20, 20, 20, 19, 19, 21, 21, 21, 22, 22],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 30, 30, 27, 27, 27, 28, 28]
  ],
  32: [
    [0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 2, 2, 3, 3, 3, 6, 6, 5, 5, 5, 7, 7, 4, 4, 4],
    [8, 8, 9, 10, 10, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13],
    [14, 29, 29, 15, 15, 16, 16, 16, 17, 17, 18, 18, 18, 19, 19, 20, 20, 20, 19, 19, 21, 21, 21, 22, 22],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 30, 30, 27, 27, 27, 28, 28]
  ],
  40: [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
    [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  ]
};

const stand3 = {
  stands: 5,
  28: [
    [0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 2, 2, 3, 3, 3, 6, 6, 5, 5, 5, 7, 7, 4, 4, 4],
    [8, 8, 9, 10, 10, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13],
    [14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 16, 16, 16, 16, 16, 17, 17, 17, 17, 17, 15, 15, 15, 15, 15],
    [18, 18, 18, 18, 18, 19, 19, 19, 19, 19, 20, 20, 20, 20, 20, 22, 22, 22, 22, 22, 21, 21, 21, 21, 21],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 26, 26, 27, 27, 27, 27, 27]
  ],
  29: [
    [0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 2, 2, 3, 3, 3, 6, 6, 5, 5, 5, 7, 7, 4, 4, 4],
    [8, 8, 9, 10, 10, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13],
    [14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 16, 16, 16, 16, 16, 17, 17, 17, 17, 17, 15, 15, 15, 15, 15],
    [18, 18, 18, 18, 18, 19, 19, 19, 19, 19, 20, 20, 20, 20, 20, 22, 22, 22, 22, 22, 21, 21, 21, 21, 21],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 26, 26, 27, 27, 27, 28, 28]
  ],
  30: [ //29
    [0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 2, 2, 3, 3, 3, 6, 6, 5, 5, 5, 7, 7, 4, 4, 4],
    [8, 8, 9, 10, 10, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13],
    [14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 16, 16, 16, 16, 16, 17, 17, 17, 17, 17, 15, 15, 15, 15, 15],
    [18, 18, 18, 18, 18, 19, 19, 19, 19, 19, 29, 29, 20, 20, 20, 22, 22, 22, 22, 22, 21, 21, 21, 21, 21],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 26, 26, 27, 27, 27, 28, 28]
  ],
  31: [
    [0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 2, 2, 3, 3, 3, 6, 6, 5, 5, 5, 7, 7, 4, 4, 4],
    [8, 8, 9, 10, 10, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13],
    [14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 16, 16, 16, 16, 16, 17, 17, 17, 17, 17, 15, 15, 15, 15, 15],
    [18, 18, 18, 18, 18, 19, 19, 19, 19, 19, 29, 29, 20, 20, 20, 22, 22, 22, 22, 22, 21, 21, 21, 21, 21],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 30, 30, 27, 27, 27, 28, 28]
  ],
  32: [
    [0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 2, 2, 3, 3, 3, 6, 6, 5, 5, 5, 7, 7, 4, 4, 4],
    [8, 8, 9, 10, 10, 8, 8, 9, 10, 10, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13, 11, 11, 12, 13, 13],
    [14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 16, 16, 16, 16, 16, 17, 17, 17, 17, 17, 15, 15, 15, 15, 15],
    [18, 18, 18, 18, 18, 19, 19, 19, 19, 19, 29, 29, 20, 20, 20, 22, 22, 22, 22, 22, 21, 21, 21, 21, 21],
    [23, 23, 23, 23, 23, 24, 24, 24, 24, 24, 25, 25, 25, 25, 25, 26, 26, 26, 30, 30, 27, 27, 27, 28, 28]
  ],
  40: [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
    [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  ]
};

export const standsConfig = {
  1: stand1,
  2: stand2,
  3: stand3
};

export const desktopStandId = 1;
